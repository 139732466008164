import { useNavigate } from "react-router";

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <div className="overflow-hidden h-full max-w-[120px] md:max-w-[170px]">
      <div className="flex flex-col justify-center h-full rounded cursor-pointer">
        <img
          onClick={() => navigate("/overview")}
          className="relative z-10 h-full cursor-pointer"
          src={`${process.env.PUBLIC_URL}/finansco_logo.svg`}
          alt="logo"
        />
      </div>
    </div>
  );
};
