import { createTheme } from '@mui/material/styles';
import tailwindTheme from "./tailwindTheme";

const theme = createTheme({
    palette: {
        primary: {
            main: tailwindTheme.colors.button, // Same primary color as Tailwind
        },
    },
});

export default theme;