import { ReactNode } from "react";
import { useGetContactInfo } from "api/common/useGetContactInfo";
import { useGetContractIdData } from "providers/ContractIdProvider";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { NoPortfolios } from "./components/NoPortfolios";

interface PortfolioGuardProps {
  children: ReactNode;
}

export const PortfolioGuard = ({ children }: PortfolioGuardProps) => {
  const { selectedContactId } = useGetContractIdData();

  const { data: { portfolios } = { portfolios: [] }, loading } = useGetContactInfo(
    false,
    selectedContactId
  );
  if (loading) return <LoadingIndicator center />;
  if (portfolios.length === 0) {
    return <NoPortfolios />;
  }

  return <>{children}</>;
};
